@import url('node_modules/ol/ol.css');
@import url('./bottom-sheet.css');
@import url('../features/data-catalogue/styles/data-catalogue.css');


.map{
  height: 100%;
  width: 100%;

}
