
body .mat-mdc-dialog-container .mat-mdc-dialog-content {
  padding-top: 1rem;
}

mat-dialog-actions footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}



@media (max-width: 600px) {
  .myDialogClass {
    max-width: 95dvw !important;
    max-height: 95dvh !important;
    height: 100% !important;
    width: 100% !important;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .myDialogClass {
    max-width: 80dvw !important;
    max-height: 80dvh !important;
    height: 100% !important;
    width: 100% !important;
  }
}

@media (min-width: 1025px) {
  .myDialogClass{
    max-width: 70dvw !important;
    max-height: 80dvh !important;
    height: fit-content !important;
    width: fit-content !important;
  }

  .myDialogClass.map-container{
    height: 100% !important;
    width: 100% !important;
  }
}



.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}


.content {
  min-height: 200px;
}

.footer {
  text-align: right;
  display: flex;
  justify-content: end;
  padding-block: 1rem;
  gap: 1rem;
}

.title {
  font-weight: bold;
}

.minimize, .maximize, .close {
  font-size: 14px;
}

.get-capabilities-layer-content {
  flex-grow: 1;
  padding-top: 0.5rem;
  min-height: 40vh;
  max-height: calc(60vh - 200px);
  overflow-y: auto;
}

.layer-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}

.layer-radio-button {
  margin: 5px;
}


.dialog-titlebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
}

.dialog-content {
  padding-bottom: 1.5rem;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
