.mdc-snackbar.success-snack-bar .mdc-snackbar__surface{
  background-color: var(--green);
  border-color: var(--green-border);
  color: #FFFFFF;
}

.mdc-snackbar .mat-mdc-snack-bar-label
{
  --mdc-snackbar-supporting-text-size: 1.1rem;
  text-align: center;
}

.mdc-snackbar.error-snack-bar .mdc-snackbar__surface {
  background-color: var(--red);
  border-color: var(--red-border);
  color: #FFFFFF;
}


.mdc-snackbar.notification-snack-bar .mdc-snackbar__surface{
  background-color: var(--blue);
  border-color: var(--blue-border);
  color: #FFFFFF;
}
