
mat-card.detail-card
{
  display: grid;
  grid-auto-rows: 60px 1fr 60px;
  min-width: 35%;
}

mat-card.detail-card mat-form-field {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}

.detail-card mat-card-header {
  padding:0;
  padding-inline: 1rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid whitesmoke;
}


.detail-card mat-card-content {
  overflow-y: auto;
  padding-block: 1rem;
}


.detail-card mat-card-footer {
  display: flex;
  align-items: center;
  border-top: 1px solid whitesmoke;
  padding-inline: 1rem;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1.34375em;
}

@media (max-width: 768px) {
  mat-card.detail-card
  {
    max-width: calc(100% - 4rem);
    min-width: 100%;
  }

}
