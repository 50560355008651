.esk-tooltip {
    --tooltip-arrow-size: 5px;
    --tooltip-arrow-color: rgba(0, 0, 0, 0.7);
    --tooltip-margin: 8px;

    z-index: 4000;
    max-width: 300px;        /* or any specific width */
    word-wrap: break-word;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    pointer-events: none;
  }


  .esk-tooltip::after {
    content: '';
    position: absolute;
  }


  .esk-tooltip--right::after {
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    border-width: var(--tooltip-arrow-size);
    border-style: solid;
    border-color: transparent var(--tooltip-arrow-color) transparent transparent;
  }

  .esk-tooltip--left::after {
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    border-width: var(--tooltip-arrow-size);
    border-style: solid;
    border-color: transparent transparent transparent var(--tooltip-arrow-color);
  }

  .esk-tooltip--top::after {
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: var(--tooltip-arrow-size);
    border-style: solid;
    border-color: var(--tooltip-arrow-color) transparent transparent transparent;
  }

  .esk-tooltip--bottom::after {
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: var(--tooltip-arrow-size);
    border-style: solid;
    border-color: transparent var(--tooltip-arrow-color) transparent transparent;
  }
