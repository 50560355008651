.esk-toolbar-tooltip {
  color: var(--measure-tool-primary) !important;
  font-size: 1.25rem;
  -webkit-text-stroke-width: 1px; /* Thicker stroke for a halo effect */
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.5); /* White stroke for the halo */

  /* Fallback for non-webkit browsers */
  text-shadow:
    -1px -1px 0 rgba(255, 255, 255, 0.5),
    1px -1px 0 rgba(255, 255, 255, 0.5),
    -1px 1px 0 rgba(255, 255, 255, 0.5),
    1px 1px 0 rgba(255, 255, 255, 0.5);
}


.esk-toolbar {
  position: absolute;
  max-height: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  gap: var(--size-1);
  padding-block: var(--size-2);
  padding-inline: var(--size-2);
  left: 0.5rem;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: var(--radius-2);
  box-shadow: var(--shadow-2);
  top: 75px;
  z-index: 1;
  max-width: 3rem;
  transition: width 300ms ease-in-out;
}

.esk-tool {
  position: relative;
  border-radius: 2px;
  color: #495057;
  aspect-ratio: 1;
  background-color: transparent;
  transition: background-color 0.1s ease-in-out;
  border: none;
  cursor: pointer;
  width: 100%;
}

.esk-tool--disabled,
.esk-tool:disabled {
  cursor: not-allowed;
  pointer-events: none;

  color: #c0c0c0;
}

.esk-tool:active {
  color: var(--blue);
}

.esk-tool button {
  width: 100%;
  background-color: transparent;
  color: black;
  cursor: pointer;
}

.esk-tool:hover,
.esk-tool *:hover {
  color: var(--blue);
}

.esk-tool button:hover {
  outline: none;
}

.esk-tool--active {

  color: var(--blue);
}

.measure-type > * {
  display: block;
}

.measure-color > * {
  display: block;
}

.measure-type--select {
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
}

.measure-color--input {
  width: 100px;
}

.measure-tool-options {
  width: 80%;
  padding: 1em;
  display: grid;
}


.mat-checkbox {
  padding: 8px;
}

.menu-button {
  padding: 0;
  padding-inline-end: var(--size-1);
  background: none;
  border: none;
  cursor: pointer;
}

.chevron-icon {
  font-size: 12px;
}


.tool-group{
  border: 1px solid #495057af;
  border-radius: var(--radius-2);
}
