
.tab {
  --_tab-bg: #7692FF;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--_tab-bg);
  width: 200px;
  padding-block: 0.25rem;
  padding-inline: 1rem;
  border-radius: 8px 8px 0 0 ;
  display: grid;
  justify-content: center;

}

.tab.tab--bottom {
  bottom: 0;
  left: 50%;
}



.tab:not(.tab--bottom)::before,
.tab:not(.tab--bottom)::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 90%;
  width: 90vw;
  height: 5px;
  background-color: var(--_tab-bg);
  z-index: 1;
}

.tab:not(.tab--bottom)::after {
  left: auto;
  right: 90%;
}

.tab > button {

  background-color: unset;
width: 50px;
cursor: pointer;
  border: none;
}

.tab i
{
  color: white;
}

body .mat-bottom-sheet-container {
  min-width: 100vw;
  padding:unset;
  overflow: unset;
  border-radius: unset;
  min-height: 25dvh;
}

