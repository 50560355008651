:root {
  --pastel-green: #92CCA6;
  --pastel-green-border: #81B18F;
  --pastel-green-hover: #70956A;

  --pastel-blue: #A2D5F2;
  --pastel-blue-border: #91B9D3;
  --pastel-blue-hover: #7E9DB0;

  --pastel-pink: #F2ACD6;
  --pastel-pink-border: #D399BD;
  --pastel-pink-hover: #B585A1;

  --pastel-purple: #CBA2F2;
  --pastel-purple-border: #B18FD3;
  --pastel-purple-hover: #987DB0;

  --pastel-yellow: #F2E0A2;
  --pastel-yellow-border: #D3C891;
  --pastel-yellow-hover: #B6AD7E;

  --pastel-red: #F2A2A2;
  --pastel-red-border: #D38F8F;
  --pastel-red-hover: #B57C7C;


  --outline-button-main-color: hsl(0, 0%, 50%);
  --outline-button-hover-text-color: hsl(0, 0%, 40%);
  --outline-button-hover-border-color: hsl(0, 0%, 30%);

  --outline-button-active-text-color: hsl(0, 0%, 30%);
  --outline-button-active-border-color: hsl(0, 0%, 20%);

    /* Main Colors */
    --green: var(--lime-6);
    --blue: var(--blue-6);
    --orange: hsl(24, 100%, 60%);
    --purple: hsl(267, 60%, 55%);
    --yellow: var(--yellow-6);
    --red: var(--red-6);

    /* Hover Colors */
    --green-hover: var(--lime-7);
    --blue-hover:var(--blue-7);
    --orange-hover: hsl(24, 100%, 50%);
    --purple-hover: hsl(267, 60%, 45%);
    --yellow-hover: var(--yellow-7);
    --red-hover: var(--red-7);

    /* Border Colors */
    --green-border: var(--lime-7);
    --blue-border: var(--blue-7);
    --orange-border: hsl(24, 100%, 50%);
    --purple-border: hsl(267, 60%, 45%);
    --yellow-border: var(--yellow-7);
    --red-border: var(--red-7);



}
.button {
  border: 0.0625rem solid var(--button-border-color);
  background-color: var(--button-bg);
  color: var(--button-txt-color);
  border-radius: 0.5em;
  cursor: pointer;
  font-size: 1rem;
  gap: 0.25rem;
  padding: 0.75rem 1rem;
  transition: all 200ms ease-in-out;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background-color: var(--button-hover-bg);
  border-color: var(--button-hover-border-color);
  color: var(--button-hover-txt-color);
}

.button:disabled {
  background-color: #ddd;
  border-color: #ddd;
  color: #999;
  cursor: not-allowed;
}

.button--brand {
   --button-bg: var(--brand-clr);
   --button-txt-color:white;

  --button-hover-bg: var(--brand-clr-darken);
  --button-hover-txt-color:white;
}


.button--green-fill {
  --button-border-color: var(--green-border);
  --button-bg: var(--green);
  --button-hover-bg: var(--green-hover);
  --button-txt-color: white;
  --button-hover-txt-color:white;
}

.button--yellow-fill {
  --button-border-color: var(--yellow-border);
  --button-bg: var(--yellow);
  --button-hover-bg: var(--yellow-hover);
  --button-txt-color: white;
  --button-hover-txt-color:white;
}

.button--purple-fill {
  --button-bg: var(--pastel-purple);
  --button-border-color: var(--pastel-purple);
  --button-hover-bg: #8461C9;
}

.button--green {
  --button-bg: white;
  --button-border-color: var(--green);
  --button-txt-color: var(--green);
  --button-hover-bg: var(--green);
  --button-hover-txt-color: white
}

.button--orange {
  --button-bg: var(--pastel-orange);
  --button-border-color: var(--pastel-orange);
  --button-hover-bg: #FF8C69;
}

.button--danger{
  --button-bg: var(--red-6);
  --button-border-color: var(--red-border);
  --button-hover-bg: var(--red-hover);

  --button-txt-color: white;
  --button-hover-txt-color:white;

}


.button--icon{
  --button-bg: transparent;
  --button-border-color: unset;
  --button-hover-bg: unset;
  --button-hover-border-color: unset;

  --button-txt-color:rgba(128,128,128,0.15);
  --button-hover-txt-color:#aaa;
padding: 0;
}


.button--muted{
  --button-bg: #f0f0f0;
  --button-border-color: #cccccc;
  --button-hover-bg: #e0e0e0;
  --button-hover-border-color: #bbbbbb;

  --button-txt-color:#666666;

}




.button--warn{
  --button-border-color: var(--yellow-border);
  --button-bg: var(--yellow);
  --button-hover-bg: var(--yellow-hover);
  --button-txt-color: white;
  --button-hover-txt-color:white;
}

.button--blue {
  --button-bg: var(--blue);
  --button-border-color: var(--blue-border);
  --button-hover-bg: var(--blue-hover);
  --button-txt-color:white;
  --button-hover-txt-color:white;
}

.button--black {
  --button-txt-color: var(--pastel-white);
  --button-bg: var(--pastel-black);
  --button-border-color: var(--pastel-black);
  --button-hover-bg: #1a1a1a;
}

.button--black-outline {
  --button-txt-color: var(--outline-button-main-color);
  --button-bg: transparent;
  --button-border-color: var(--outline-button-main-color);
  --button-hover-txt-color: white;
  --button-hover-bg: var(--outline-button-hover-border-color);
  --button-hover-border-color: var(--outline-button-active-border-color);
}
.button--equal-width {
  width: 300px; /* or whatever width you want */
}
.button--xs {
font-size: 0.75rem;
}

.button--sm {
  font-size: 0.875rem;
}

.button--md {
  font-size: 1rem;
}

.button--xl {
  font-size: 1.25rem;
}

.button--xxl {
  font-size: 1.5rem;
}

.button--full_width{
  width: 100%;
}

.button--action {
  border-radius: 50%;
  border:unset;
  width: 30px;
  aspect-ratio: 1;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
    justify-content: center;
    align-items: center;
    padding: unset;
}

.button--p-xy-1{
  padding: 0.25rem;
}


