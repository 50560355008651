



@import url('./styles/reset.css');
@import url("/node_modules/quill/dist/quill.snow.css");
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import url("./app//shared/directives/tooltip/esk-tooltip.css");
@import url("/node_modules/@fortawesome/fontawesome-free/css/all.min.css");
@import url("/node_modules/open-props/open-props.min.css");
@import url("./app/map/styles/map.css");
@import url("/node_modules/ol/ol.css");
@import url("./styles/esk-toolbar.css");
@import url("./styles/display.helpers.css");
@import url("./styles/buttons.css");
@import url("./styles/forms.css");
@import url("./styles/dialog.css");
@import url("./styles/toast.css");
@import url("./app/@admin/styles/admin.css");
@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body {
  height: 100vh;
  width: 100vw;
  font-weight: 400;
}

body {
  margin: 0;
  font-family: "Outfit", sans-serif;
  display: grid;
}

.mdc-notched-outline__notch {
  border-style: none;
}

.ql-container {
  height: 200px;
  overflow-y: auto;
}

:root {
  --scrollbar-width: 4px;
  --scrollbar-thumb-border-radius: calc(var(--scrollbar-width) / 2);

  --brand-h: 93.46;
  --brand-s: 30.23%;
  --brand-l: 33.73%;

  --brand-clr: hsl(var(--brand-h), var(--brand-s), var(--brand-l));

  --brand-clr-light: hsl(
    var(--brand-h),
    var(--brand-s),
    calc(var(--brand-l) + 15%)
  );

  --brand-clr-darken: hsl(
    var(--brand-h),
    var(--brand-s),
    calc(var(--brand-l) - 10%)
  );

  --dialog-bg: #fff;
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: var(--scrollbar-thumb-border-radius);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Scrollbar for Firefox */
/* These styles only work in Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

*::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

*::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: var(--scrollbar-thumb-border-radius);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

[data-lastpass-icon-root] {
  display: none !important;
}

* {
}

.d-grid {
  display: grid;
  grid-gap: 20px;
}

.d-flex {
  display: flex;
}

@page {
  margin: 0;
}
body {
  margin: 0;
}
.sheet {
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
}

/** Paper sizes **/
body.A3 .sheet {
  width: 297mm;
  height: 419mm;
}
body.A3.Landscape .sheet {
  width: 420mm;
  height: 296mm;
}
body.A4 .sheet {
  width: 210mm;
  height: 296mm;
}
body.A4.Landscape .sheet {
  width: 297mm;
  height: 209mm;
}
body.A5 .sheet {
  width: 148mm;
  height: 209mm;
}
body.A5.Landscape .sheet {
  width: 210mm;
  height: 147mm;
}
body.letter .sheet {
  width: 216mm;
  height: 279mm;
}
body.letter.Landscape .sheet {
  width: 280mm;
  height: 215mm;
}
body.legal .sheet {
  width: 216mm;
  height: 356mm;
}
body.legal.Landscape .sheet {
  width: 357mm;
  height: 215mm;
}

.sheet.padding-5mm {
  padding: 5mm;
}

.sheet.padding-10mm {
  padding: 10mm;
}
.sheet.padding-15mm {
  padding: 15mm;
}
.sheet.padding-20mm {
  padding: 20mm;
}
.sheet.padding-25mm {
  padding: 25mm;
}

/** For screen preview **/
@media screen {
  body:has(.sheet) {
    background: #e0e0e0;
  }
  .sheet {
    background: white;
    box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
    margin: 5mm auto;
  }

  .sheet.left {
    margin: 0;
    margin-top: 5mm;
    margin-bottom: 5mm;
    margin-left: 10mm;
  }
}

/** Fix for Chrome issue #273306 **/
@media print {
  body.A3.Landscape {
    width: 420mm;
  }
  body.A3,
  body.A4.Landscape {
    width: 297mm;
  }
  body.A4,
  body.A5.Landscape {
    width: 210mm;
  }
  body.A5 {
    width: 148mm;
  }
  body.letter,
  body.legal {
    width: 216mm;
  }
  body.letter.Landscape {
    width: 280mm;
  }
  body.legal.Landscape {
    width: 357mm;
  }
}

app-root {
  overflow: hidden;
}

.basemap-option {
  --mat-option-label-text-size: 0.9rem;
}

.basemap-select {
  --mat-select-trigger-text-size: 0.9rem;
}

.cdk-overlay-container{
  z-index: unset;
}

.test-class{
  display: grid;
  padding: 0;
  padding-left: 1rem;
}

i.fa.fa-arrow-right-from-bracket
{
  transform: rotate(180deg);
}

.context-menu {
  background-color: var(--gray-0);
  border-radius: var(--radius-2);
  box-shadow: var(--shadow-2);
  padding: var(--size-1) 0;
  list-style: none;
  min-width: 15rem;
  font-family: var(--font-sans);
}

.context-menu li {
  padding: var(--size-2) var(--size-3); /* Space around items */
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  color: var(--gray-9); /* Dark text */
  font-size: 1rem; /* Responsive base font size */
}

.context-menu li:hover {
  background-color: var(--gray-2); /* Hover background */
}

.context-menu li:active {
  background-color: var(--gray-3); /* Active background */
}

.context-menu li:not(:last-child) {
  border-bottom: 1px solid var(--gray-3); /* Divider between items */
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .context-menu {
    min-width: 15rem; /* Smaller menu for mobile */
  }

  .context-menu li {
    font-size: 0.875rem; /* Slightly smaller font on mobile */
    padding: var(--size-1) var(--size-2); /* Reduced padding */
  }
}
