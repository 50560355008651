
.data-catalogue{
  --search-input-height:50px;
  --gap: 0.5rem;
  display: grid;
  gap: var(--gap);
  grid-auto-flow: row;
  grid-template-rows: var(--search-input-height) calc(65dvh - calc(var(--search-input-height) + (var(--gap) * 2)));
}

.dc-accordion {

  --collapsed-height: 70px;
  --border: solid 1px rgba(204, 204, 204, 1);
  --padding-size: 1rem;
  --description-clr: rgba(153, 153, 153, 1);
  --header-hover-clr: var(--brand-clr-light);
  --header-bg-clr:  var(--brand-clr-light);


  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}

.dc-accordion-item {
  border-top: var(--border);
  border-left: var(--border);
  border-bottom:  var(--border);
  border-right:  var(--border);
  display: flex;
  flex-direction: column;
}

.dc-accordion-item:has(.dc-accordion-item-header.sticky-header)
{
  border-top: unset;
}

.dc-accordion-item + .dc-accordion-item {
  border-top: none;
}

.dc-accordion-item-header {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  height: var(--collapsed-height);
}

.dc-accordion-item-header.sticky-header {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: var(--header-bg-clr, white);
  color: white;
  --description-clr:white;

  border-top: var(--border);
}


.dc-accordion-item-description {
  font-size: 0.85em;
  color: var(--description-clr, black);
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.dc-accordion-item-header,
.dc-accordion-item-body {
  padding: var(--padding-size, 16px);
}

.dc-accordion-item-header:hover {
  cursor: pointer;
  color: white;
  --description-clr:white;
  background-color: var(--header-hover-clr, white);
}

.dc-accordion-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.dc-accordion-item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.button--icon{
  --button-bg: transparent;
  --button-border-color: unset;
  --button-hover-bg: unset;
  --button-hover-border-color: unset;

  --button-txt-color:rgba(128,128,128,0.15);
  --button-hover-txt-color:#aaa;

}

.dc-accordion-item-header.sticky-header > .dc-accordion-item-description >.button--icon,
.dc-accordion-item-header:hover > .dc-accordion-item-description >.button--icon {
  --button-txt-color:rgba(255, 255, 255, 0.15);
  --button-hover-txt-color:white;
}

.expanded:not(:first-of-type)
{
  margin-top: 0.5rem;
}

